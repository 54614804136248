<script lang="ts"></script>
<template>
  <section
    class="max-w-[1028px] flex flex-col items-center gap-[32px] px-[20px] lg:px-0 container-body"
  >
    <div class="flex flex-col lg:items-center gap-[32px] lg:flex-row">
      <div class="flex flex-col gap-[24px] lg:flex-1">
        <h1
          class="mobile-heading-2 text-color-primary-50 capitalize md:desktop-heading-2"
        >
          Creando Experiencias que trascienden con tecnologías de vanguardia
        </h1>
        <div class="flex flex-col gap-[24px] md:gap-[80px] lg:gap-[24px] md:flex-row md:items-center lg:flex-col lg:items-start">
        <p class="mobile-body text-color-gray-500 md:desktop-body md:flex-1 lg:flex-auto">
          En iCeDigital, Creamos soluciones digitales personalizadas que
          impulsan tu negocio, priorizando calidad, usabilidad y resultados
          efectivos.
        </p>
        <ul
          class="mobile-body text-color-primary-50 flex flex-col gap-[16px] md:desktop-body md:flex-1 lg:flex-auto"
        >
          <li class="flex items-center gap-[8px]">
            <span
              class="icon-[material-symbols--arrow-right-alt-rounded] text-color-primary-500 text-[16px] md:text-[24px]"
            ></span
            >Innovación
          </li>
          <li class="flex items-center gap-[8px]">
            <span
              class="icon-[material-symbols--arrow-right-alt-rounded] text-color-primary-500 text-[16px] md:text-[24px]"
            ></span
            >Adaptabilidad
          </li>
          <li class="flex items-center gap-[8px]">
            <span
              class="icon-[material-symbols--arrow-right-alt-rounded] text-color-primary-500 text-[16px] md:text-[24px]"
            ></span
            >Centrado al cliente
          </li>
        </ul>
      </div>
      </div>
      <div class="h-[259px] lg:h-[490px] lg:flex-1">
        <img
          src="@/assets/img/imgabout.jpg"
          alt="Imagen de equipo"
          class="h-full w-full object-cover md:object-cover rounded-[20px]"
        />
      </div>
    </div>
  </section>
</template>
<style scoped></style>
