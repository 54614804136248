import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative bg-color-bg-1" }
const _hoisted_2 = { class: "flex flex-col items-center pt-[56px] gap-[100px] md:gap-[130px] z-10 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServicesHome = _resolveComponent("ServicesHome")!
  const _component_TestimonialsHome = _resolveComponent("TestimonialsHome")!
  const _component_CompanysAbout = _resolveComponent("CompanysAbout")!
  const _component_CallAction = _resolveComponent("CallAction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_ServicesHome),
      _createVNode(_component_TestimonialsHome),
      _createVNode(_component_CompanysAbout),
      _createVNode(_component_CallAction)
    ])
  ]))
}