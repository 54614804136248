<template>
  <section
    class="max-w-[1028px] flex flex-col items-center gap-[32px] px-[20px] py-[64px] md:px-0 container-body"
  >
    <div class="flex flex-col items-center gap-[16px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-white text-center">
        Listo para comenzar tu proyecto
      </h2>
      <p class="mobile-body md:desktop-body text-color-gray-500 text-center">
        Embárquemonos juntos en un viaje de innovación y creatividad.
      </p>
    </div>
    <router-link
      to="/contact"
      class="w-full md:w-auto py-[15px] px-[30px] flex justify-center items-center bg-color-primary-500 rounded-[50px] hover:bg-color-primary-600 text-color-gray-50 mobile-body md:desktop-body"
      >Estimar un Proyecto</router-link
    >
  </section>
</template>
<script lang="ts"></script>
<style scoped></style>
