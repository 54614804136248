import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative bg-color-bg-1" }
const _hoisted_2 = { class: "flex flex-col items-center pt-[56px] gap-[100px] md:gap-[130px] z-10 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoAbout = _resolveComponent("InfoAbout")!
  const _component_CompanyAbout = _resolveComponent("CompanyAbout")!
  const _component_CounterAbout = _resolveComponent("CounterAbout")!
  const _component_TeamAbout = _resolveComponent("TeamAbout")!
  const _component_CallAction = _resolveComponent("CallAction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_InfoAbout),
      _createVNode(_component_CompanyAbout),
      _createVNode(_component_CounterAbout),
      _createVNode(_component_TeamAbout),
      _createVNode(_component_CallAction)
    ])
  ]))
}