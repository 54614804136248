import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMain = _resolveComponent("HeaderMain")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterMain = _resolveComponent("FooterMain")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderMain),
    _createVNode(_component_router_view),
    _createVNode(_component_FooterMain)
  ], 64))
}