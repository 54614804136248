<script lang="ts"></script>
<template>
  <section
    class="max-w-[1028px] flex flex-col items-center gap-[32px] px-[20px] md:px-0 container-body"
  >
    <div class="w-full">
      <h1 class="mobile-heading-1 text-color-gray-50 md:desktop-heading-1">Contáctenos</h1>
    </div>
    <div>
        <div>
            <div></div>
        </div>
    </div>
  </section>
</template>
<style scoped></style>
