<script lang="ts"></script>
<template>
  <section class="bg-color-bg-2 w-full">
    <div
      class="max-w-[1028px] flex flex-col items-center md:flex-row md:justify-between px-[20px] lg:px-0 gap-[28px] py-[64px] container-body"
    >
      <div class="flex flex-col gap-[4px] items-center md:items-start">
        <span class="mobile-heading-1 text-white md:desktop-heading-1 text-left"
          >+3</span
        >
        <p class="mobile-body text-color-gray-500 md:desktop-body text-center">
          Años de experiencia
        </p>
      </div>
      <div class="flex flex-col gap-[4px] items-center md:items-start">
        <span class="mobile-heading-1 text-white md:desktop-heading-1 text-left"
          >+30</span
        >
        <p class="mobile-body text-color-gray-500 md:desktop-body text-center">
          Clientes Felices
        </p>
      </div>
      <div class="flex flex-col gap-[4px] items-center md:items-start">
        <span class="mobile-heading-1 text-white md:desktop-heading-1 text-left"
          >+32</span
        >
        <p class="mobile-body text-color-gray-500 md:desktop-body text-center">
          Proyectos Exitosos
        </p>
      </div>
      <div class="flex flex-col gap-[4px] items-center md:items-start">
        <span class="mobile-heading-1 text-white md:desktop-heading-1 text-left"
          >+5</span
        >
        <p class="mobile-body text-color-gray-500 md:desktop-body text-center">
          Reseñas Positivas
        </p>
      </div>
    </div>
  </section>
</template>
<style scoped></style>
