<template>
  <section
    class="max-w-[1028px] flex flex-col items-center gap-[32px] px-[20px] md:px-0 container-body"
  >
    <div class="flex flex-col items-center gap-[16px]">
      <span class="mobile-caption md:desktop-caption text-color-primary-500"
        >Testimoniales</span
      >
      <h2
        class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50 text-center"
      >
        Lo que dicen nuestros clientes
      </h2>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[32px]">
      <div
        class="flex flex-col gap-[16px] p-[24px] rounded-[20px] bg-color-bg-2"
      >
        <div class="flex gap-[16px] items-center">
          <div class="w-[64px] h-[64px]">
            <img
              src="@/assets/img/avatar.jpg"
              alt="Avatar"
              class="h-full w-full object-cover object-top rounded-[10px]"
            />
          </div>
          <div class="flex flex-col gap-[4px]">
            <h4 class="text-color-gray-50 mobile-heading-4 md:desktop-heading-4">Dreix Chiquin</h4>
            <span class="text-color-gray-500 mobile-caption md:desktop-caption"
              >CEO At mith & Co</span
            >
          </div>
        </div>
        <p class="text-color-gray-500 mobile-body md:desktop-body">
          Trabajar con iCe-Digital fue un cambio absoluto para nosotros. Su
          equipo creativo dio vida a nuestra marca con un impresionante rediseño
          del sitio web que impulsó inmediatamente nuestra presencia en línea.
        </p>
      </div>
      <div
        class="flex flex-col gap-[16px] p-[24px] rounded-[20px] bg-color-bg-2"
      >
        <div class="flex gap-[16px] items-center">
          <div class="w-[64px] h-[64px]">
            <img
              src="@/assets/img/avatar.jpg"
              alt="Avatar"
              class="h-full w-full object-cover object-top rounded-[10px]"
            />
          </div>
          <div class="flex flex-col gap-[4px]">
            <h4 class="text-color-gray-50 mobile-heading-4 md:desktop-heading-4">Dreix Chiquin</h4>
            <span class="text-color-gray-500 mobile-caption md:desktop-caption"
              >CEO At mith & Co</span
            >
          </div>
        </div>
        <p class="text-color-gray-500 mobile-body md:desktop-body">
          Trabajar con iCe-Digital fue un cambio absoluto para nosotros. Su
          equipo creativo dio vida a nuestra marca con un impresionante rediseño
          del sitio web que impulsó inmediatamente nuestra presencia en línea.
        </p>
      </div>
      <div
        class="flex flex-col gap-[16px] p-[24px] rounded-[20px] bg-color-bg-2"
      >
        <div class="flex gap-[16px] items-center">
          <div class="w-[64px] h-[64px]">
            <img
              src="@/assets/img/avatar.jpg"
              alt="Avatar"
              class="h-full w-full object-cover object-top rounded-[10px]"
            />
          </div>
          <div class="flex flex-col gap-[4px]">
            <h4 class="text-color-gray-50 mobile-heading-4 md:desktop-heading-4">Dreix Chiquin</h4>
            <span class="text-color-gray-500 mobile-caption md:desktop-caption"
              >CEO At mith & Co</span
            >
          </div>
        </div>
        <p class="text-color-gray-500 mobile-body md:desktop-body">
          Trabajar con iCe-Digital fue un cambio absoluto para nosotros. Su
          equipo creativo dio vida a nuestra marca con un impresionante rediseño
          del sitio web que impulsó inmediatamente nuestra presencia en línea.
        </p>
      </div>
    </div>
  </section>
</template>
<script lang="ts"></script>
<style scoped></style>
